<template>
  <!-- 绘制道路 -->
  <el-dialog
    title="绘制道路"
    :close-on-click-modal="false"
    :visible.sync="roadHideAdd"
    width="1050px"
    :before-close="closeRoadHideAdd"
  >
    <div style="width: 100%">
      <baidu-map
        class="roadMap"
        :center="mapPoint"
        :zoom="15"
        @mousemove="syncPolyline"
        @click="paintPolyline"
        @rightclick="newPolyline"
        :scroll-wheel-zoom="true"
      >
        <bm-control>
          <div style="display: flex">
            <el-button
              type="primary"
              style="margin: 5px 0 0 5px; padding: 10px"
              @click="toggle('polyline')"
            >
              {{ polyline.editing ? "停止绘制" : "开始绘制" }}
            </el-button>
            <el-button
              type="primary"
              style="margin: 5px 0 0 5px; padding: 10px"
              @click="editRoad"
            >
              {{ editVisible ? "关闭编辑道路" : "开启编辑道路" }}
            </el-button>
            <el-color-picker
              style="margin: 5px 0 0 5px"
              v-model="color"
            ></el-color-picker>
          </div>
        </bm-control>
        <bm-polyline
          :path="path"
          v-for="path of polyline.paths"
          :key="path.toString()"
          :stroke-weight="3"
          :editing="editVisible"
          :stroke-color="color"
        ></bm-polyline>
      </baidu-map>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeRoadHideAdd">取 消</el-button>
      <el-button type="primary" @click="savePosition">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      roadHideAdd: false, // 绘制道路弹窗
      editVisible: false, // 开启编辑道路功能
      mapPoint: { lng: 0, lat: 0 },
      polyline: {
        editing: false,
        paths: [],
      },
      color: "red", // 道路颜色
    };
  },
  methods: {
    // 关闭绘制道路弹窗
    closeRoadHideAdd() {
      this.polyline.editing = false;
      this.polyline.paths = [];
      this.editVisible = false;
      this.$nextTick(() => {
        this.roadHideAdd = false;
      });
    },
    editRoad () {
      this.editVisible = !this.editVisible;
    },
    toggle (name) {
      this[name].editing = !this[name].editing;
      if (this.polyline.paths && this.polyline.editing) {
        this.polyline.paths = [];
      }
    },
    syncPolyline (e) {
      if (!this.polyline.editing) {
        return;
      }
      const { paths } = this.polyline;
      if (!paths.length) {
        return;
      }
      const path = paths[paths.length - 1];
      if (!path.length) {
        return;
      }
      if (path.length === 1) {
        path.push(e.point);
      }
      this.$set(path, path.length - 1, e.point);
    },
    newPolyline (e) {
      if (!this.polyline.editing) {
        return;
      }
      const { paths } = this.polyline;
      if (!paths.length) {
        paths.push([]);
      }
      const path = paths[paths.length - 1];
      path.pop();
      if (path.length) {
        paths.push([]);
      }
    },
    paintPolyline (e) {
      if (!this.polyline.editing) {
        return;
      }
      const { paths } = this.polyline;
      !paths.length && paths.push([]);
      paths[paths.length - 1].push(e.point);
    },
    // 保存绘制道路经纬度数组
    savePosition () {
      this.form.shapeDesc = this.polyline.paths[0];
      this.roadPosition = JSON.stringify(this.polyline.paths[0]);
      this.$nextTick(() => {
        this.polyline.editing = false;
        this.polyline.paths = [];
        this.editVisible = false;
        this.roadHideAdd = false;
      });
    },
    // 绘制地图弹窗
    openRoadMap () {
      if (this.form.address != "") {
        this.roadHideAdd = true;
        this.mapPoint.lng = this.form.longitude;
        this.mapPoint.lat = this.form.latitude;
        if (this.form.shapeDesc.length > 0) {
          this.polyline.paths[0] = this.form.shapeDesc;
          this.polyline.paths[1] = [];
        }
      } else {
        this.$message("请先选择道路地址")
      }
    },
  },
};
</script>

<style scoped>
.roadMap {
  width: 100%;
  height: 400px;
}
</style>

